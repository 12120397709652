import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import CTA from "../../../components/cta"
import ServiceAreas from "../../../components/serviceAreas"
import SubServicesAlt from "../../../components/subServicesAlt"
import Education from "../../../components/education"
import HeroSectionAlt from "../../../components/heroSectionAlt"
import Testimonials from "../../../components/testimonials"

function UnclogSinkDrain() {
  const data = useStaticQuery(graphql`
    query unclogSinkDrainImages {
      sinkDrain: file(relativePath: { eq: "sink-repair.jpg" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO
        title="Sink Drain Repair"
        description={`If your sink drain is clogged, we can fix it! With over 30 years of residential plumbing experience, so we'll unclog your sink drain quickly and easily`}
      />
      <HeroSectionAlt
        h1={`Sink Drain Repair`}
        h2={`Have a Master Plumber Unclog Your Sink Drain`}
        h3={`Klein Plumbing Services has over 30 years of experience unclogging and repairing sink drains. A sink that won't drain properly is a sink that's unusable. Our experts can have yours working like new.`}
        p1={`Seemingly minor clogs can cause damage to your entire plumbing system if left unchecked. Our experts can discover the root of your clog and repair it before you end up with leaking - or worse, ruptured - pipes. We've unclogged countless sinks over three decades of serving the North Houston area, which is why our service is fast, professional, and affordable.`}
        heroImg={data.sinkDrain.childImageSharp.fluid}
      />
      <Education
        mainTitle={`Signs Your Sink Drain Is Clogged`}
        titleOne={`Slow Draining`}
        descriptionOne={`A healthy sink drains with no backed-up water; If your sink is draining slowly (or not at all), you have a clogged drain.`}
        titleTwo={`Foul Odors`}
        descriptionTwo={`Unpleasant odors coming from your drain could be an early sign of a clog - even if other signs are not yet obvious.`}
        titleThree={`Strange Sounds`}
        descriptionThree={`Gurgling noises coming from your drain could be a non-visual indicator that your sink is clogged.`}
      />
      <SubServicesAlt
        mainTitle={`Our Sink Drain Services`}
        titleOne={`Finding the Clog`}
        descriptionOne={`Our master plumber can find the clog in your sink drain before it can cause any damage to your plumbing.`}
        titleTwo={`Breaking Up the Blockage`}
        descriptionTwo={`After we have identified the root of the clog, we use industry-leading equipment and methods to break it up.`}
        titleThree={`Post-Operation Checks`}
        descriptionThree={`After removing the blockage, our plumber will verify that your sink drains like it should and that everything is working properly.`}
      />
      {/* <Testimonials mainTitle={`Our Customers`} /> */}
      <ServiceAreas description={`sinks`} subDescription={`clogged sink`} />
      <CTA
        title={`Get a Free Quote to Fix Your Sink Drain Now`}
        buttonTitle={`Talk With a Master Plumber`}
      />
    </Layout>
  )
}

export default UnclogSinkDrain
